import React,{useState, useEffect} from 'react'
import { ChatState } from "../Context/ChatProvider"
import axios from "axios"
import {Box, Stack, useToast,Text} from "@chakra-ui/react"
import {Button } from '@chakra-ui/button'
import { AddIcon } from '@chakra-ui/icons'
import { Chatloading } from './Chatloading'
import { getSender } from '../config/ChatLogics'
import GroupChatModal from './miscellaneous/GroupChatModal';


 const MyChats = ({fetchAgain}) => {
  const [loggedUser, setLoggedUser] = useState();
  const { user,setUser, selectedChat, setSelectedChat,chats, setChats } = ChatState();
  const toast = useToast();

  const fetchChats = async() => {
    console.log(user._id);
    try{
      
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,

        },
      };
      const {data} = await axios.get("https://ftrrrfffgfg.onrender.com/api/chat", config);
        console.log(data);
      setChats(data);
  }
catch(error){
toast({
  title: "Error Occured!",
  description: "Failed to Load the Search Results",
   status: 'error',
  duration: 5000,
 isClosable: true,
  position: "bottom-left",

});

}
  };
  useEffect(() => {
    setLoggedUser(JSON.parse(localStorage.getItem("userInfo")));
    fetchChats();
  },[fetchAgain])
  return (
   <Box
  display={{base: selectedChat ? "none" : "flex", md: "flex"}}
  flexDir = "column"
  alignItems="center"
  p={3}
  bg ="white"
  w={{base: "100%" , md: "31%"}}
  borderRadius = "lg"
  borderWidth ="1px"
>
  <Box
  pb={3}
  px={3}
  fontSize={{base: "28px", md: "30px"}}
  fontFamily="Work sans"
  display="flex"
  w="100%"
  justifyContent="space-between"
  alignItems= "center"
  >My Chats
  <GroupChatModal>
  <Button
  display="flex"
  fontSize={{base: "17px",md: "10px",lg: "17px"}}
  rightIcon={<AddIcon/>}
  >New Group Chat</Button>
  </GroupChatModal>
  </Box>
<Box
display="flex"
flexDir="column"
p={3}
bg = "#F8F8F8"
w = "100%"
h="100%"
borderRadius="lg"
overflowY="hidden"
>
  {chats ? (
     <Stack overflowY= 'scroll'>
      {chats.map((chat) => {
        return (
        <Box 
        onClick={()=> setSelectedChat(chat)}
        cursor="pointer"
        bg={selectedChat === chat ? "#38B2AC" : "#E8E8E8"}
        color={selectedChat === chat ? "white" : "black"}
        px = {3}
        py = {2}
        borderRadius ="lg"
        key={chat._id}
        
        >
          
         <Text>
        
          {!chat.isGroupChat
            ? getSender(loggedUser, chat.users)
            : chat.chatName}          
          
         </Text>
         {chat.latestMessage && (
                  <Text fontSize="xs">
                    <b>{chat.latestMessage.sender.name} : </b>
                    {chat.latestMessage.content.length > 50
                      ? chat.latestMessage.content.substring(0, 51) + "..."
                      : chat.latestMessage.content}
                  </Text>
                )}

        </Box>
        );
      })}
     </Stack>
  ): (
    <Chatloading/>
  )}
</Box>


   </Box>
  )
};
export default MyChats;

