import { FormControl, FormLabel, VStack,Input, InputGroup, Button,InputRightElement } from '@chakra-ui/react'
import React,{useState} from 'react';
import { useToast } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom';
import axios from "axios";

const Signup = () => {
    const [show, setShow] = useState(false);
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [confirmpassword, setConfirmpassword] = useState();
    const [pic, setPic] = useState();
    const [loading, setLoading] = useState(false);
    const toast = useToast();
    const history = useHistory();

    const handleClick = () => {
        setShow(!show);
    }
    const postDetails = (pics) =>{
        setLoading(true);
        if(pics === undefined){
            toast({
                title: 'Please Select an Image!',
                status: 'warning',
                duration: 5000,
                isClosable: true,
                position: "bottom",
              });
              return;
        }
        console.log(pics);
        if(pics.type === "image/jpeg" || pics.type === "image/png"){
            const data = new FormData();
            data.append("file", pics);
            data.append("upload_preset", "chat-app");
            data.append("cloud_name", "dcai9uksp");
            fetch("https://api.cloudinary.com/v1_1/dcai9uksp/image/upload", {
                method: "post",
                body: data,
                
            })
            .then((res) => res.json())
            .then((data) => {
                setPic(data.url.toString());
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            })
        } else{
            toast({
                title: 'Please Select an Image!',
                status: 'warning',
                duration: 5000,
                isClosable: true,
                position: "bottom",
            });
            setLoading(false);
            return;
        }
    };
    const submitHandler = async() => {
          setLoading(true);
          if(!name || !email || !password || !confirmpassword){
            toast({
                title: 'Please Fill all the Fields',
                status: 'warning',
                duration: 5000,
                isClosable: true,
                position: "bottom",
            });
            setLoading(false);
            return;
          }
          if(password !== confirmpassword){
            toast({
                title: 'Password do not match',
                status: 'warning',
                duration: 5000,
                isClosable: true,
                position: "bottom",
            });
            return;
          }
          try{
            const config = {
                headers: {
                    "Content-type": "application/json",
                },
            };
            const {data} = await axios.post(
                "https://ftrrrfffgfg.onrender.com/api/user",
                { name,email,password,pic},
                config
            );
            toast({
                title: "Registration successful",
                status: 'Success',
                duration: 5000,
                isClosable: true,
                position: "bottom",
            });
            localStorage.setItem("userInfo", JSON.stringify(data));
            setLoading(false);
            history.push("/chats");
          }
          catch(error){
            toast({
                title: "Error Occured",
                description: error.response.data.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: "bottom",
            });
          }
    }
  return (
    <VStack spacing= "3px" color="black">
        <FormControl id="first-name" isRequired>
            <FormLabel>Name</FormLabel>
            <Input
            placeholder="Enter your Name"
            onChange = {(e) => setName(e.target.value)}
            
            />
        </FormControl>
        <FormControl id="email" isRequired>
            <FormLabel>Email</FormLabel>
            <Input
            placeholder="Enter your Email"
            onChange = {(e) => setEmail(e.target.value)}
            
            />
        </FormControl>
        <FormControl id="password" isRequired>
            <FormLabel>Password</FormLabel>
            <InputGroup>
            <Input
            type= {show? "text"  :  "password"}
            placeholder="Enter your password"
            onChange = {(e) => setPassword(e.target.value)}
            />
            <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={handleClick}>
                    {show ? "Hide" : "show"}
                </Button>
            </InputRightElement>
            </InputGroup>
        </FormControl>

        <FormControl id="Confirmpassword" isRequired>
            <FormLabel>Confirm Password</FormLabel>
            <InputGroup>
            <Input
            type= {show? "text"  :  "password"}
            placeholder="Confirm password"
            onChange = {(e) => setConfirmpassword(e.target.value)}
            />
            <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={handleClick}>
                    {show ? "Hide" : "show"}
                </Button>
            </InputRightElement>
            </InputGroup>
        </FormControl>


<FormControl id="pic">
    <FormLabel>Upload your Picture</FormLabel>
   <Input
   type="file"
   p={1.5}
   accept="image/*"
   onChange={(e) => postDetails(e.target.files[0])}
   />
</FormControl>
<Button
colorScheme= "blue"
// variantColor="blue"
// color= "white"
// backgroundColor="blue"
width ="100%"
style={{marginTop: 15}}
onClick={submitHandler}
isLoading = {loading}
> Sign up</Button>
    </VStack>
  );
};

export default Signup